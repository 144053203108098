<template>
  <el-container>
    <el-aside width="250px">
      <img class="logo" alt="SentioManager" :src="contextLogo" />
      <el-menu
        :default-active="activeLink"
        background-color="#F7F9FA"
        text-color="var(--s-600)"
        router
      >
        <template v-for="(menuItem, index) in accessibleMenuItems">
          <el-submenu
            v-if="menuItem.children && menuItem.children.length > 0"
            :key="index"
            :index="menuItem.toStateName"
          >
            <template slot="title">
              <div class="nav-item">
                <img
                  v-if="menuItem.icon"
                  alt="icon"
                  :src="'/dashboard/images/' + menuItem.icon"
                />
                {{ menuItem.label }}
              </div>
            </template>
            <el-menu-item
              v-for="(child, index) in menuItem.children"
              :key="index"
              :index="child.toStateName"
              :route="{ name: child.toStateName, accountId: accountId }"
            >
              <div class="nav-item">
                <img
                  v-if="child.icon"
                  alt="icon"
                  :src="'/dashboard/images/' + child.icon"
                />
                {{ child.label }}
              </div>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :key="index"
            :index="menuItem.toStateName"
            :route="{ name: menuItem.toStateName, accountId: accountId }"
          >
            <div class="nav-item">
              <img
                v-if="menuItem.icon"
                alt="icon"
                :src="'/dashboard/images/' + menuItem.icon"
              />
              {{ menuItem.label }}
            </div>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <router-view />
  </el-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  mounted: function() {
    const routeMatchedNames = this.$route.matched.map(it => it.name);
    this.accessibleMenuItems.forEach(menuItem => {
      if (menuItem.children) {
        menuItem = menuItem.children.find(it =>
          routeMatchedNames.includes(it.toStateName)
        );
      }
      if (menuItem && routeMatchedNames.includes(menuItem.toStateName))
        this.activeLink = menuItem.toStateName;
    });
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
    }),
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const resultMenuItems = [];
      this.menuItems.forEach(menuItem => {
        if (menuItem.children) {
          menuItem.children = menuItem.children.filter(
            childMenuItem =>
              window.roleWiseRoutes[this.currentRole].indexOf(
                childMenuItem.toStateName
              ) > -1
          );
          if (menuItem.children.length) resultMenuItems.push(menuItem);
        } else if (
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
        ) {
          resultMenuItems.push(menuItem);
        }
      });
      return resultMenuItems;
    },
    menuItems() {
      const accountId = this.accountId;
      if (accountId === null) {
        return [];
      }
      return [
        {
          label: 'Tenant Admin',
          icon: 'SentioManagerMenuIcons/TenantAdministrationIcon.svg',
          toStateName: 'tenant_admin_index',
        },
        {
          icon: 'SentioManagerMenuIcons/AgentAdministrationIcon.svg',
          label: 'Agent Admin',
          toStateName: 'agent_admin_index',
        },
        {
          icon: 'SentioManagerMenuIcons/ConversationFlowIcon.svg',
          label: 'Bot Setup',
          toStateName: 'bot_setup_index',
        },
        {
          icon: 'SentioManagerMenuIcons/HandoffManagement.svg',
          label: 'Handoff Management',
          toStateName: 'handoff-manager',
          children: [
            {
              icon: 'SentioManagerMenuIcons/CustomerProfileIcon.svg',
              label: 'Customer Profile',
              toStateName: 'customer_profile_index',
            },
            {
              icon: 'SentioManagerMenuIcons/IntentIcon.svg',
              label: 'Intent Classifier',
              toStateName: 'intent_index',
            },
            {
              icon: 'SentioManagerMenuIcons/ResponseIcon.svg',
              label: 'Response',
              toStateName: 'response_index',
            },
          ],
        },
        {
          icon: 'SentioManagerMenuIcons/SentioInsights.svg',
          label: 'Sentio Insights',
          toStateName: 'sentio-insights',
          children: [
            {
              icon: 'SentioManagerMenuIcons/ChatReports.svg',
              label: 'Chats report',
              toStateName: 'chats_report_index',
            },
            {
              icon: 'SentioManagerMenuIcons/AgentAdministrationIcon.svg',
              label: 'Agents report',
              toStateName: 'agents_report_index',
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      activeLink: null,
      contextLogo: '/brand-assets/sentio.png',
    };
  },
};
</script>

<style lang="scss" scoped>
$--active-nav-item-color: #ecf1f3;
.logo {
  padding: 20px;
  width: 200px;
}

.el-menu {
  border: none;

  img {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }

  .nav-item {
    display: flex;
    align-items: center;
  }

  .el-submenu::v-deep .el-submenu__title {
    border-radius: 4px;

    &:hover {
      background: $--active-nav-item-color !important;
    }

    font-weight: 500;
    line-height: 40px;
    height: 40px;
  }
}

.el-menu-item,
.el-submenu::v-deep .el-menu-item {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;

  &:hover {
    background: $--active-nav-item-color !important;
  }

  &.is-active {
    background: $--active-nav-item-color !important;
  }
}

.el-aside > .el-menu > .el-menu-item {
  font-weight: 500;
}

.el-aside {
  padding: var(--space-small);
  background: #f7f9fa;
  border-right: 1px solid var(--s-50);
}
</style>
