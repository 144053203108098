import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import SkillSettingApi from '../../api/skillSetting';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getSkillSettings($state) {
    return $state.records;
  },
  getVerifiedSkillSettings($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_SKILL_SETTING_FETCHING_STATUS, true);
    try {
      const response = await SkillSettingApi.get();
      commit(types.default.SET_SKILL_SETTING_FETCHING_STATUS, false);
      commit(types.default.SET_SKILL_SETTINGS, response.data);
    } catch (error) {
      commit(types.default.SET_SKILL_SETTING_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_SKILL_SETTING_CREATING_STATUS, true);
    try {
      const response = await SkillSettingApi.create(agentInfo);
      commit(types.default.ADD_SKILL_SETTING, response.data);
      commit(types.default.SET_SKILL_SETTING_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_SKILL_SETTING_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_SKILL_SETTING_UPDATING_STATUS, true);
    try {
      const response = await SkillSettingApi.update(id, agentParams);
      commit(types.default.EDIT_SKILL_SETTING, response.data);
      commit(types.default.SET_SKILL_SETTING_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_SKILL_SETTING_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },

  updatePresence: async ({ commit }, data) => {
    commit(types.default.SET_SKILL_SETTING_UPDATING_STATUS, true);
    commit(types.default.UPDATE_SKILL_SETTINGS_PRESENCE, data);
    commit(types.default.SET_SKILL_SETTING_UPDATING_STATUS, false);
  },

  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_SKILL_SETTING_DELETING_STATUS, true);
    try {
      await SkillSettingApi.delete(agentId);
      commit(types.default.DELETE_SKILL_SETTING, agentId);
      commit(types.default.SET_SKILL_SETTING_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_SKILL_SETTING_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_SKILL_SETTING_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_SKILL_SETTING_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_SKILL_SETTING_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_SKILL_SETTING_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_SKILL_SETTINGS]: MutationHelpers.set,
  [types.default.ADD_SKILL_SETTING]: MutationHelpers.create,
  [types.default.EDIT_SKILL_SETTING]: MutationHelpers.update,
  [types.default.DELETE_SKILL_SETTING]: MutationHelpers.destroy,
  [types.default.UPDATE_SKILL_SETTINGS_PRESENCE]: MutationHelpers.updatePresence,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
