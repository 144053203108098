<template>
  <div class="column content-box">
    <h5>Response</h5>
    <div v-if="account.id" class="content-box">
      <el-form
        ref="response"
        label-position="left"
        label-width="220px"
        :rules="rules"
        :model="account.responses"
      >
        <el-form-item label="Search" prop="search">
          <el-input v-model="account.responses.search" />
        </el-form-item>
        <el-form-item label="Connects" prop="connects">
          <el-input v-model="account.responses.connects" />
        </el-form-item>
        <el-form-item label="Not available" prop="not_available">
          <el-input v-model="account.responses.not_available" />
        </el-form-item>
        <el-form-item label="PWT message" prop="pwt">
          <el-input v-model="account.responses.pwt" />
        </el-form-item>
        <el-form-item label="Intent not configured" prop="unknown_intent">
          <el-input v-model="account.responses.unknown_intent" />
        </el-form-item>
        <el-form-item label="Intent declassified" prop="declassified_indent">
          <el-input v-model="account.responses.declassified_indent" />
        </el-form-item>
        <el-form-item label="Delayed by UCPT" prop="pairing_delayed">
          <el-input v-model="account.responses.pairing_delayed" />
        </el-form-item>
        <el-form-item label="Reserve Agent SMS" prop="notify_reserve_agent">
          <el-input v-model="account.responses.notify_reserve_agent" />
        </el-form-item>
        <el-form-item
          label="Discharge Agent SMS"
          prop="discharge_reserve_agent"
        >
          <el-input v-model="account.responses.discharge_reserve_agent" />
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" plain @click="updateAccount">
        Update
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [globalConfigMixin],
  data() {
    var validatePWT = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the message'));
      } else if (!value.includes('%{pwt}')) {
        callback(
          new Error(
            'Must contain %{pwt}. Where %{pwt} will be the value determined by the Nucleus algorithm.'
          )
        );
      } else {
        callback();
      }
    };
    const default_rules = [
      {
        required: true,
        message: 'Please input the message',
        trigger: 'blur',
      },
    ];
    return {
      account: {},
      rules: {
        search: default_rules,
        connects: default_rules,
        not_available: default_rules,
        unknown_intent: default_rules,
        declassified_indent: default_rules,
        pairing_delayed: default_rules,
        notify_reserve_agent: default_rules,
        discharge_reserve_agent: default_rules,
        pwt: [
          {
            required: true,
            validator: validatePWT,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
      getUIFlags: 'accounts/getUIFlags',
    }),
  },
  mounted() {
    this.$store.dispatch('accounts/get').then(() => {
      this.account = this.getAccount(this.accountId);
    });
  },
  methods: {
    async updateAccount() {
      this.$refs.response.validate(async valid => {
        if (valid) {
          await this.$store.dispatch('accounts/update', this.account);
          return this.showAlert('Updated');
        }
        return false;
      });
    },

    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss">
h4 {
  //text-align: center;
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.el-select {
  .el-input {
    margin-bottom: 0;
  }

  input {
    margin: 0 !important;
  }
}

.search-intent {
  &.el-input {
    margin-bottom: 20px;
    width: 300px !important;

    input {
      margin: 0 !important;
    }
  }
}

.el-input-number {
  .el-input {
    width: 100% !important;
    margin: 0 !important;
  }

  input {
    margin: 0 !important;
  }
}

.el-form {
  .el-form {
    width: 100% !important;
  }
}

.el-button.add-intent {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

input[readonly] {
  background-color: white !important;
}
</style>
