/* eslint no-console: 0 */
/* global axios */

import ApiClient from '../ApiClient';

class ChatsReportAPI extends ApiClient {
  constructor() {
    super('nucleus/chats_reports', { accountScoped: true });
  }

  getReport(reportParams) {
    return axios.get(`${this.url}`, {
      params: reportParams,
    });
  }
}

export default new ChatsReportAPI();
