<template>
  <div class="bot-setup content-box">
    <h5>Bot Setup</h5>

    <el-table
      v-loading="uiBotsFlags.isFetching"
      size="mini"
      :data="botsList"
      empty-text="No Data"
      @cell-mouse-enter="cellEnter"
      @cell-mouse-leave="cellLeave"
    >
      <el-table-column label="ID" prop="id" width="100" />
      <el-table-column label="Vendor" prop="bot_vendor_configuration.vendor" />
      <el-table-column label="Bot" prop="name" />
      <el-table-column>
        <div v-show="scope.row.details" slot-scope="scope">
          <el-popconfirm
            :title="`Are you sure to delete this ${scope.row.name}?`"
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="deleteBot(scope.row)"
          >
            <el-button
              v-if="scope.row.id"
              slot="reference"
              v-loading="scope.row.loading"
              type="text"
              icon="el-icon-close"
            />
          </el-popconfirm>
        </div>
      </el-table-column>
    </el-table>
    <el-button type="primary" size="small" @click="showModal">
      Add Bot
    </el-button>

    <el-dialog
      v-loading="isSaving"
      width="30%"
      title="Add Bot"
      :visible.sync="modalVisible"
    >
      <el-steps style="padding-bottom: 40px" :active="activeStep" align-center>
        <el-step />
        <el-step />
        <el-step />
      </el-steps>

      <el-form
        ref="botForm"
        v-loading="uiExternalBotsFlags.isFetching"
        label-position="left"
        :rules="rules"
        :model="botItem"
        label-width="120px"
        size="small"
        inline
      >
        <template v-if="activeStep === 0">
          <el-form-item label="Vendor" prop="vendor">
            <el-select
              v-model="botItem.vendor"
              :disabled="!vendors.length"
              placeholder="Select Vendor"
            >
              <el-option
                v-for="item in vendors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </template>

        <template v-if="activeStep === 1">
          <el-form-item label="Org ID" prop="external_account_id">
            <el-input v-model="botItem.external_account_id" />
          </el-form-item>
          <el-form-item label="Access Token" prop="connection.api_key">
            <el-input v-model="botItem.connection.api_key" />
          </el-form-item>
        </template>

        <template v-if="activeStep === 2">
          <el-form-item label="Bot" prop="external_id">
            <el-select
              v-model="botItem.external_id"
              :disabled="!externalBots.length"
              placeholder="Select Bot"
            >
              <el-option
                v-for="item in externalBots"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="activeStep"
          size="small"
          type="primary"
          plain
          @click="prev"
        >
          <i class="el-icon-arrow-left" /> Back
        </el-button>
        <el-button type="primary" size="small" @click="next">
          {{ activeStep === 2 ? 'Done' : 'Next' }}
          <i v-if="activeStep !== 2" class="el-icon-arrow-right" />
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  data() {
    return {
      botItem: {},
      activeStep: 0,
      vendors: [{ name: 'Rulai', id: 'rulai' }],
      modalVisible: false,
      rules: {
        external_id: [
          { required: true, message: 'Please select bot', trigger: 'change' },
        ],
        'connection.api_key': [
          {
            required: true,
            message: 'Please input an access token',
            trigger: 'change',
          },
        ],
        external_account_id: [
          {
            required: true,
            message: 'Please input an organization id',
            trigger: 'change',
          },
        ],
        vendor: [
          {
            required: true,
            message: 'Please select vendor',
            trigger: 'change',
          },
        ],
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      botsList: 'sentioAdmin/bots/getBots',
      uiBotsFlags: 'sentioAdmin/bots/getUIFlags',
      externalBots: 'sentioAdmin/externalBots/getExternalBots',
      uiExternalBotsFlags: 'sentioAdmin/externalBots/getUIFlags',
    }),
  },
  mounted() {
    this.$store.dispatch('sentioAdmin/bots/get');
  },
  methods: {
    showModal() {
      this.botItem = {
        vendor: this.vendors[0].id,
        connection: {},
      };
      this.modalVisible = true;
      this.activeStep = 0;
      Vue.nextTick(() => {
        this.$refs.botForm.clearValidate();
      });
    },
    next() {
      this.$refs.botForm.validate(async valid => {
        if (valid) {
          // eslint-disable-next-line default-case
          switch (this.activeStep) {
            case 0:
              this.activeStep += 1;
              break;
            case 1:
              this.createBotVendorConfiguration();
              break;
            case 2:
              this.createBot();
              break;
          }
        }
      });
    },
    async createBot() {
      const { name } = this.externalBots.find(
        it => it.id === this.botItem.external_id
      );
      await this.$store.dispatch('sentioAdmin/bots/create', {
        name,
        ...this.botItem,
      });
      this.modalVisible = false;
      this.showAlert('Created');
    },
    async createBotVendorConfiguration() {
      try {
        const { id } = await this.$store.dispatch(
          'sentioAdmin/botVendorConfigurations/create',
          this.botItem
        );
        this.botItem.bot_vendor_configuration_id = id;
        await this.$store.dispatch('sentioAdmin/externalBots/get', id);
        this.activeStep += 1;
      } catch (e) {
        const message = e.response.data.error || 'Something went wrong!';
        this.showAlert(message);
      }
    },
    prev() {
      this.activeStep -= 1;
      Vue.nextTick(() => {
        this.$refs.botForm.clearValidate();
      });
    },
    cellEnter(row) {
      Vue.set(row, 'details', true);
    },
    cellLeave(row) {
      Vue.set(row, 'details', false);
    },
    closeModals() {
      this.skillModalVisible = false;
    },
    async deleteBot(item) {
      Vue.set(item, 'loading', true);
      await this.$store.dispatch('sentioAdmin/bots/delete', item.id);
      Vue.set(item, 'loading', false);
      this.showAlert('Deleted');
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss">
.bot-setup {
  .el-table {
    padding-bottom: 20px;

    .el-button {
      padding: 0 4px !important;
    }
  }

  h4 {
    //text-align: center;
  }

  h6 {
    padding-bottom: 10px;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .el-select {
    .el-input {
      margin-bottom: 0;
    }

    input {
      margin: 0 !important;
    }
  }

  .search-intent {
    &.el-input {
      margin-bottom: 20px;
      width: 300px !important;

      input {
        margin: 0 !important;
      }
    }
  }

  .el-input {
    input {
      margin: 0 !important;
    }

    width: 100% !important;
    margin: 0 !important;
  }

  .el-input-number {
    .el-input {
      width: 100% !important;
      margin: 0 !important;
    }

    input {
      margin: 0 !important;
    }
  }

  .el-form {
    .el-form {
      width: 100% !important;
    }
  }

  .el-button.add-intent {
    margin-top: 20px;
  }

  table thead th {
    font-weight: 600;
    color: #383636;
    text-transform: none;
  }

  table tr,
  table td {
    border: none !important;
  }

  .el-input-number {
    width: 140px;
  }

  .el-table::before {
    display: none;
  }

  .el-select {
    width: 100%;
  }

  .el-dialog__body {
    min-height: 250px;
  }

  input[readonly] {
    background-color: white !important;
  }

  input {
    width: 320px;
  }

  .el-table {
    width: 900px;
  }
}
</style>
