<template>
  <div class="flex-container actions--container">
    <woot-button
      v-if="
        (currentContact.phone_number ||
          currentContact.availability_status === 'online') &&
          !deviceCall
      "
      v-tooltip="'Call'"
      variant="clear"
      color-scheme="secondary"
      icon="call"
      @click="twilioCall"
    />
    <woot-button
      v-if="deviceCall"
      v-tooltip="'Hang Up'"
      variant="clear"
      color-scheme="alert"
      icon="call-end"
      @click="outgoingCallHangupButton"
    />
    <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <resolve-action
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal';
import ResolveAction from '../../buttons/ResolveAction';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';
import { Device } from '@twilio/voice-sdk';

/* global axios */

function addDeviceListeners(device) {
  device.on('registered', function() {
    console.log('Twilio.Device Ready to make and receive calls!');
  });

  device.on('error', function(error) {
    console.log('Twilio.Device Error: ' + error.message);
  });

  // device.audio.on("deviceChange", updateAllAudioDevices.bind(device));

  // Show audio selection UI if it is supported by the browser.
  if (device.audio.isOutputSelectionSupported) {
    console.log(
      'Show audio selection UI if it is supported by the browser.',
      device.audio.isOutputSelectionSupported
    );
  }
}

export default {
  components: {
    EmailTranscriptModal,
    ResolveAction,
  },
  mixins: [alertMixin, clickaway],
  props: {
    senderId: {},
  },
  data() {
    return {
      device: undefined,
      deviceCall: undefined,
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](this.senderId);
    },
    contactInbox() {
      return (
        this.currentContact?.contact_inboxes.length &&
        this.currentContact.contact_inboxes[0]
      );
    },
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    outgoingCallHangupButton() {
      console.log('Hanging up ...');
      this.deviceCall.disconnect();
      this.deviceCall = undefined;
    },
    async twilioCall() {
      const {
        data: { token, identity },
      } = await axios.get('/api/v1/twilio_voices/token');
      console.log('identity', identity);
      const device = new Device(token, {
        logLevel: 1,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ['opus', 'pcmu'],
      });
      this.device = device;
      addDeviceListeners(device);
      device.register();

      let params = {
        // get the phone number to call from the DOM
        To: this.currentContact.phone_number || this.contactInbox.source_id,
        // To: '+15105194023',
      };

      if (device) {
        console.log(`Attempting to call ${params.To} ...`);

        const call = await device.connect({ params });
        this.deviceCall = call;
        // add listeners to the Call
        // "accepted" means the call has finished connecting and the state is now "open"
        call.on('accept', () => {
          this.showAlert('Accept');
        });
        call.on('disconnect', () => {
          this.deviceCall = undefined;
        });
        call.on('cancel', () => {
          this.deviceCall = undefined;
        });
      } else {
        console.log('Unable to make call.');
      }
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
  },
};
</script>
<style scoped lang="scss">
.actions--container {
  align-items: center;

  .resolve-actions {
    margin-left: var(--space-small);
  }
}

.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}

.actions--container {
  position: relative;
}

.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}

.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
</style>
