import { Pie, mixins } from 'vue-chartjs';

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: ['chartData'],
  mounted() {
    this.renderChart(this.chartData, {
      rotation: -Math.PI,
      cutoutPercentage: 80,
      circumference: Math.PI,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
        },
      },
      tooltips: {
        callbacks: {
          // eslint-disable-next-line consistent-return
          label(tooltipItem, data) {
            try {
              let label = ' ' + data.labels[tooltipItem.index] || '';

              if (label) {
                label += ': ';
              }

              const sum = data.datasets[0].data.reduce(
                (accumulator, curValue) => {
                  return accumulator + (curValue || 0);
                },
                0
              );
              const value =
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] || 0;

              label += Number((value / sum) * 100).toFixed(0) + '%';
              return label + ` (${value})`;
              // eslint-disable-next-line no-empty
            } catch (error) {}
          },
        },
      },
    });
  },
};
