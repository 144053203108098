<template>
  <div class="column content-box intent-classifier">
    <div class="content-box">
      <el-input v-model="search" class="search-intent" size="mini" placeholder="Type to search" />
      <el-table
        v-loading="intentUiFlags.isFetching"
        empty-text="No Data"
        :data="
          intentList.filter(
            data =>
              !search || data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        :default-sort="{ prop: 'name', order: 'ascending' }"
        @cell-mouse-enter="cellEnter"
        @cell-mouse-leave="cellLeave"
      >
        <el-table-column prop="name" class-name="specify" sortable label="Name">
          <template slot="header">
            <span class="label-text">Name</span>
          </template>
          <div slot-scope="scope" :title="scope.row.name">
            {{ scope.row.name }}
          </div>
        </el-table-column>
        <el-table-column
          prop="action_status"
          sortable
          label="Action"
          align="center"
          width="100"
          class-name="specify"
          :sort-method="(a, b) => sortStringColumn(a, b, 'action_status')"
          :filter-method="filterHandler"
          :filters="actionFilters"
        >
          <template slot="header">
            <span class="label-text">Action</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Skills"
          width="220"
          :filters="skillFilters"
          :filter-method="filterSkillColumn"
        >
          <div
            slot-scope="scope"
            :title="
              scope.row.required_skills &&
                scope.row.required_skills.map(({ name }) => name)
            "
          >
            <el-tag
              v-for="skill in scope.row.required_skills"
              :key="skill.id"
              size="small"
            >
              {{ skill.name }}
            </el-tag>
          </div>
        </el-table-column>
        <el-table-column
          label="Mediaflow"
          width="150"
          :filters="mediaflowFilters"
          :filter-method="filterMediaflowColumn"
        >
          <div
            slot-scope="scope"
            :title="
              scope.row.mediaflow_setting && scope.row.mediaflow_setting.name
            "
          >
            <el-tag v-if="scope.row.mediaflow_setting" size="small">
              {{ scope.row.mediaflow_setting.name }}
            </el-tag>
          </div>
        </el-table-column>
        <el-table-column
          prop="importance"
          align="center"
          sortable
          width="135"
          :sort-method="(a, b) => sortNumberColumn(a, b, 'importance')"
          class-name="specify"
          label="Importance"
          :filter-method="filterHandler"
          :filters="filtersUrgencyAndImportance"
        >
          <template slot="header">
            <span class="label-text">Importance</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="urgency"
          sortable
          align="center"
          width="115"
          label="Urgency"
          class-name="specify"
          :sort-method="(a, b) => sortNumberColumn(a, b, 'urgency')"
          :filter-method="filterHandler"
          :filters="filtersUrgencyAndImportance"
        >
          <template slot="header">
            <span class="label-text">Urgency</span>
          </template>
        </el-table-column>
        <el-table-column width="50" align="center">
          <div v-show="scope.row.details" slot-scope="scope">
            <el-button
              icon="el-icon-edit"
              type="text"
              @click="showDialog(scope.row)"
            />
          </div>
        </el-table-column>
      </el-table>
      <el-dialog
        width="700px"
        :title="titleDialog"
        :visible.sync="dialogFormVisible"
      >
        <el-form
          ref="ruleForm"
          :rules="rules"
          label-position="left"
          :model="intentForm"
        >
          <el-form-item
            v-if="intentForm.edit"
            label-width="150px"
            label="Intent ID:"
          >
            {{ intentForm.id }}
          </el-form-item>
          <el-form-item label-width="150px" label="Name">
            {{ intentForm.name }}
          </el-form-item>
          <el-form-item
            v-if="intentForm.app_id === 'dialogflow'"
            label-width="150px"
            label="Active"
          >
            <el-checkbox v-model="intentForm.enabled" />
          </el-form-item>
          <el-form-item label-width="150px" label="Action" prop="action_status">
            <el-radio-group v-model="intentForm.action_status">
              <el-radio label="none">None</el-radio>
              <el-radio label="handoff">Handoff</el-radio>
              <el-radio label="resolve">Resolve</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="intentForm.action_status === 'handoff'"
            prop="required_skills"
            label-width="150px"
          >
            <template slot="label">
              Skills
              <el-tooltip
                effect="light"
                content="Multiple skills can be selected from the list."
                placement="top-start"
              >
                <i class="el-icon-info" />
              </el-tooltip>
            </template>
            <el-select
              v-if="skillSettings.length"
              v-model="intentForm.required_skills"
              value-key="id"
              multiple
              placeholder="Select"
            >
              <el-option
                v-for="skillSetting in skillSettings.map(({ id, name }) => ({
                  id,
                  name,
                }))"
                :key="skillSetting.id"
                :label="skillSetting.name"
                :value="skillSetting"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Mediaflow"
            prop="mediaflow_setting_id"
            label-width="150px"
          >
            <el-select
              v-if="mediflowSettings.length"
              v-model="intentForm.mediaflow_setting_id"
              value-key="id"
              placeholder="Select"
            >
              <el-option
                v-for="mediflowSetting in mediflowSettings.map(
                  ({ id, name }) => ({ id, name })
                )"
                :key="mediflowSetting.id"
                :label="mediflowSetting.name"
                :value="mediflowSetting.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="intentForm.action_status === 'handoff'"
            label="Importance level"
            label-width="150px"
          >
            <el-slider
              v-model="intentForm.importance"
              :max="4"
              :step="1"
              show-stops
            />
          </el-form-item>
          <el-form-item
            v-if="intentForm.action_status === 'handoff'"
            label="Urgency level"
            label-width="150px"
          >
            <el-slider
              v-model="intentForm.urgency"
              :max="4"
              :step="1"
              show-stops
            />
          </el-form-item>
          <el-form-item
            v-if="intentForm.action_status === 'handoff'"
            label-width="150px"
          >
            <span slot="label">
              Declassify
            </span>
            <el-tooltip
              content="Allow automatic declassification"
              effect="light"
            >
              <el-checkbox v-model="intentForm.can_deactivate" />
            </el-tooltip>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="hideDialog">Cancel</el-button>
          <el-button type="primary" @click="createOrUpdateIntent(intentForm)">
            {{ intentForm.id ? 'Save Changes' : 'Confirm' }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Vue from 'vue';

export default {
  mixins: [globalConfigMixin],
  data() {
    return {
      search: '',
      actionFilters: [
        {
          text: 'handoff',
          value: 'handoff',
        },
        {
          text: 'resolve',
          value: 'resolve',
        },
      ],
      filtersUrgencyAndImportance: [
        {
          text: '0',
          value: 0,
        },
        {
          text: '1',
          value: 1,
        },
        {
          text: '2',
          value: 2,
        },
        {
          text: '3',
          value: 3,
        },
        {
          text: '4',
          value: 4,
        },
      ],
      dialogFormVisible: false,
      rules: {
        action_status: [
          {
            required: true,
            message: 'Please select an action',
            trigger: 'change',
          },
        ],
        mediaflow_setting_id: [
          {
            required: true,
            message: 'Please select Mediaflow',
            trigger: 'change',
          },
        ],
        required_skills: [
          {
            type: 'array',
            message: 'Please select skill',
            required: true,
            trigger: 'change',
          },
        ],
      },
      intentForm: {
        name: '',
        importance: 0,
        urgency: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      intentList: 'intent/getIntents',
      skillSettings: 'skillSettings/getSkillSettings',
      mediflowSettings: 'mediflowSettings/getMediaflowSettings',
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      intentUiFlags: 'intent/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    mediaflowFilters() {
      return this.mediflowSettings.map(it => ({
        value: it.id,
        text: it.name,
      }));
    },
    skillFilters() {
      return this.skillSettings.map(it => ({
        value: it.id,
        text: it.name,
      }));
    },
    titleDialog() {
      return this.intentForm.id ? 'Edit Intent' : 'Add Intent';
    },
  },
  mounted() {
    const { botId } = this.$route.params;
    this.$store.dispatch('intent/get', botId).catch(e => {
      this.showAlert(e.data);
    });
  },
  methods: {
    cellEnter(row) {
      Vue.set(row, 'details', true);
    },
    cellLeave(row) {
      Vue.set(row, 'details', false);
    },
    filterHandler(value, row, column) {
      const property = column.property;
      return row[property] === value;
    },
    filterMediaflowColumn(value, row) {
      return row.mediaflow_setting_id === value;
    },
    filterSkillColumn(value, row) {
      return (
        row.required_skills &&
        row.required_skills.some(({ id }) => id === value)
      );
    },
    async deleteIntent(id) {
      try {
        await this.$store.dispatch('intent/delete', id);
        this.dialogFormVisible = false;
        this.showAlert('Intent deleted successfully');
      } catch (error) {
        this.showAlert('Error');
      }
    },
    hideDialog() {
      this.dialogFormVisible = false;
    },
    showDialog(intent) {
      this.intentForm = JSON.parse(JSON.stringify(intent));

      this.dialogFormVisible = true;
      Vue.nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    sortStringColumn(a, b, key) {
      a = a[key];
      b = b[key];
      // eslint-disable-next-line no-nested-ternary
      return a ? (b ? a.localeCompare(b) : -1) : 1;
    },
    sortNumberColumn(a, b, key) {
      a = a[key] === undefined ? -1 : a[key];
      b = b[key] === undefined ? -1 : b[key];
      return b - a;
    },
    async createOrUpdateIntent(intent) {
      // eslint-disable-next-line consistent-return
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            if (intent.id) {
              await this.$store.dispatch('intent/update', intent);
              this.showAlert('Updated');
            } else {
              await this.$store.dispatch('intent/create', intent);
              this.showAlert('Created');
            }
            this.dialogFormVisible = false;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error);
          }
        } else {
          return false;
        }
      });
    },
    // Show SnackBar
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss">
.phrases-input {
  .el-link:after {
    display: none !important;
  }

  .input-list {
    display: flex;
    align-items: baseline;

    .el-link {
      padding: 0 15px;
    }

    &:last-of-type {
      input {
        margin: 0 !important;
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.el-select {
  .el-input {
    margin-bottom: 0;
  }

  input {
    margin: 0 !important;
  }
}

.search-intent {
  &.el-input {
    margin-bottom: 0 !important;
    width: 300px !important;

    input {
      margin: 0 !important;
    }
  }
}

.el-input-number {
  .el-input {
    width: 100% !important;
    margin: 0 !important;
  }

  input {
    margin: 0 !important;
  }
}

.el-form {
  .el-form {
    width: 100% !important;
  }
}

.el-button.add-intent {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

input[readonly] {
  background-color: white !important;
}

.intent-id {
  .el-form-item__error {
    display: none;
  }

  .el-form-item__content {
    padding-left: 10px;
  }

  display: flex;
}

.intent-classifier {
  width: 100%;
}

.intent-classifier {
  .el-table {
    width: 100%;
    thead input {
      margin: 0 !important;
    }

    th.specify.is-center .cell {
      justify-content: center;
    }

    th.specify .cell {
      display: flex;
      align-items: center;

      .caret-wrapper {
        order: 1;
      }

      .label-text {
        order: 2;
      }

      .el-table__column-filter-trigger {
        order: 3;
      }
    }

    tr td .cell {
      & > div {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .el-button {
      padding: 0 4px !important;
    }
  }
}
</style>
