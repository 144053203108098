import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../../mutation-types';
import BotVendorConfigurationsAPI from '../../../api/sentioAdmin/botVendorConfigurations';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getBotVendorConfigs($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_FETCHING_STATUS, true);
    try {
      const response = await BotVendorConfigurationsAPI.get();
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_FETCHING_STATUS,
        false
      );
      commit(types.default.SET_NUCLEUS_BOT_VENDOR_CONFIGS, response.data);
    } catch (error) {
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_FETCHING_STATUS,
        false
      );
    }
  },
  create: async ({ commit }, params) => {
    commit(types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_CREATING_STATUS, true);
    try {
      const { data } = await BotVendorConfigurationsAPI.create(params);
      commit(types.default.ADD_NUCLEUS_BOT_VENDOR_CONFIG, data);
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_CREATING_STATUS,
        false
      );
      return data;
    } catch (error) {
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_CREATING_STATUS,
        false
      );
      throw error;
    }
  },
  update: async ({ commit }, { id, ...params }) => {
    commit(types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_UPDATING_STATUS, true);
    try {
      const response = await BotVendorConfigurationsAPI.update(id, params);
      commit(types.default.EDIT_NUCLEUS_BOT_VENDOR_CONFIG, response.data);
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_UPDATING_STATUS,
        false
      );
    } catch (error) {
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_UPDATING_STATUS,
        false
      );
      throw new Error(error);
    }
  },
  delete: async ({ commit }, botVendorConfigurationId) => {
    commit(types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_DELETING_STATUS, true);
    try {
      await BotVendorConfigurationsAPI.delete(botVendorConfigurationId);
      commit(
        types.default.DELETE_NUCLEUS_BOT_VENDOR_CONFIG,
        botVendorConfigurationId
      );
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_DELETING_STATUS,
        false
      );
    } catch (error) {
      commit(
        types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_DELETING_STATUS,
        false
      );
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_FETCHING_STATUS](
    $state,
    status
  ) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_CREATING_STATUS](
    $state,
    status
  ) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_UPDATING_STATUS](
    $state,
    status
  ) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_NUCLEUS_BOT_VENDOR_CONFIG_DELETING_STATUS](
    $state,
    status
  ) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_NUCLEUS_BOT_VENDOR_CONFIGS]: MutationHelpers.set,
  [types.default.ADD_NUCLEUS_BOT_VENDOR_CONFIG]: MutationHelpers.create,
  [types.default.EDIT_NUCLEUS_BOT_VENDOR_CONFIG]: MutationHelpers.update,
  [types.default.DELETE_NUCLEUS_BOT_VENDOR_CONFIG]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
