import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../../mutation-types';
import BotsAPI from '../../../api/sentioAdmin/bots';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getBots($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_NUCLEUS_BOT_FETCHING_STATUS, true);
    try {
      const response = await BotsAPI.get();
      commit(types.default.SET_NUCLEUS_BOT_FETCHING_STATUS, false);
      commit(types.default.SET_NUCLEUS_BOTS, response.data);
    } catch (error) {
      commit(types.default.SET_NUCLEUS_BOT_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_NUCLEUS_BOT_CREATING_STATUS, true);
    try {
      const response = await BotsAPI.create(agentInfo);
      commit(types.default.ADD_NUCLEUS_BOT, response.data);
      commit(types.default.SET_NUCLEUS_BOT_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_NUCLEUS_BOT_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_NUCLEUS_BOT_UPDATING_STATUS, true);
    try {
      const response = await BotsAPI.update(id, agentParams);
      commit(types.default.EDIT_NUCLEUS_BOT, response.data);
      commit(types.default.SET_NUCLEUS_BOT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_NUCLEUS_BOT_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_NUCLEUS_BOT_DELETING_STATUS, true);
    try {
      await BotsAPI.delete(agentId);
      commit(types.default.DELETE_NUCLEUS_BOT, agentId);
      commit(types.default.SET_NUCLEUS_BOT_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_NUCLEUS_BOT_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_NUCLEUS_BOT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_NUCLEUS_BOT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_NUCLEUS_BOT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_NUCLEUS_BOT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_NUCLEUS_BOTS]: MutationHelpers.set,
  [types.default.ADD_NUCLEUS_BOT]: MutationHelpers.create,
  [types.default.EDIT_NUCLEUS_BOT]: MutationHelpers.update,
  [types.default.DELETE_NUCLEUS_BOT]: MutationHelpers.destroy
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
