import InboxMembersAPI from '../../api/inboxMembers';

export const actions = {
  get(_, { inboxId }) {
    return InboxMembersAPI.show(inboxId);
  },
  create(_, { inboxId, agentList }) {
    return InboxMembersAPI.update({ inboxId, agentList });
  },
  createMember(_, { inboxId, agentList }) {
    return InboxMembersAPI.create({
      inbox_id: inboxId,
      user_ids: agentList,
    });
  },
  deleteMember(_, { inboxId, agentList }) {
    return InboxMembersAPI.delete({ inboxId, agentList });
  },
};

export default {
  namespaced: true,
  actions,
};
