/* eslint no-console: 0 */
import * as types from '../../mutation-types';
import chatsReport from '../../../api/nucleus/chatsReport';

const state = {
  isFetching: false,
  report: {
    summary_report: {},
    sla_report: {},
  },
};

const getters = {
  getReport(_state) {
    return _state.report;
  },
  getUIFlags($state) {
    return $state.isFetching;
  },
};

export const actions = {
  fetchReport({ commit }, reportParams) {
    commit(types.default.TOGGLE_CHATS_REPORT_LOADING, true);
    chatsReport
      .getReport(reportParams)
      .then(report => {
        commit(types.default.SET_CHATS_REPORT, report.data);
        commit(types.default.TOGGLE_CHATS_REPORT_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_CHATS_REPORT_LOADING, false);
      });
  },
};

const mutations = {
  [types.default.SET_CHATS_REPORT](_state, report) {
    _state.report = report;
  },
  [types.default.TOGGLE_CHATS_REPORT_LOADING](_state, flag) {
    _state.isFetching = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
