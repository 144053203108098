// eslint-disable-next-line no-unused-vars
/* global axios */
import ApiClient from './ApiClient';

class IntentAPI extends ApiClient {
  constructor() {
    super('agent_mediaflows', { accountScoped: true });
  }

  get(user_id) {
    return axios.get(this.url, {
      params: {
        user_id,
      },
    });
  }
}

export default new IntentAPI();
