<template>
  <el-container
    v-loading="uiMediaflowFlags.isFetching"
    direction="vertical"
    class="content-box chats-report"
  >
    <el-row
      v-if="!uiMediaflowFlags.isFetching"
      class="main-header"
      type="flex"
      :gutter="20"
    >
      <el-col :span="12">
        <h5>Chats Report</h5>
      </el-col>

      <el-col class="column-date-picker" :span="12">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          :disabled="reportIsFetching"
          :clearable="false"
          format="dd MMM yy"
          align="right"
          size="mini"
          unlink-panels
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions"
          @change="changeDate"
        />
      </el-col>
    </el-row>

    <el-row
      v-if="!uiMediaflowFlags.isFetching"
      v-loading="reportIsFetching"
      :gutter="20"
    >
      <el-col :span="12">
        <el-card class="statistics" shadow="never">
          <div class="title">
            Total number of chats
          </div>
          <div class="value">
            {{
              chatsReport.summary_report.total_chats === null
                ? '-'
                : chatsReport.summary_report.total_chats
            }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="statistics" shadow="never">
          <div class="title">
            Total chats resolved by bot
          </div>
          <div class="value">
            {{
              chatsReport.summary_report.resolved_by_bot === null
                ? '-'
                : chatsReport.summary_report.resolved_by_bot
            }}
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row
      v-if="!uiMediaflowFlags.isFetching"
      type="flex"
      class="row-bg"
      :gutter="20"
      justify="space-between"
    >
      <el-col :span="12">
        <el-form inline>
          <el-form-item label="Mediaflow: ">
            <el-select
              v-model="mediaflowSettingId"
              size="mini"
              :disabled="reportIsFetching"
              placeholder="Select Mediaflow"
              @change="changeMediaflow"
            >
              <el-option
                v-for="item in mediflowSettings"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row
      v-if="!uiMediaflowFlags.isFetching"
      v-loading="reportIsFetching"
      :gutter="20"
    >
      <el-col :span="8">
        <el-card class="statistics" shadow="never">
          <div class="title">
            Chats transferred to agents
          </div>
          <div class="value">
            {{
              chatsReport.summary_report.transferred_to_agent === null
                ? '-'
                : chatsReport.summary_report.transferred_to_agent
            }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="statistics" shadow="never">
          <div class="title">
            Abandoned chats
          </div>
          <div class="value">
            -
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="statistics" shadow="never">
          <div class="title">
            Voice enabled chats
          </div>
          <div class="value">
            -
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <div class="header">Chats</div>
          <ChatsChart class="pie-chart" :chart-data="chartChatsData" />
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <div class="header">Chats answered by agents within SLA</div>
          <ChatsChart class="pie-chart" :chart-data="chartChatsSlaData" />
        </el-card>
      </el-col>
      <el-col v-if="chartTrendsData" :span="24">
        <el-card shadow="never">
          <div class="header">Trend Analysis</div>
          <TrendsChart class="line-chart" :chart-data="chartTrendsData" />
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import ChatsChart from './ChatsChart';
import TrendsChart from './TrendsChart';
import { mapGetters } from 'vuex';

export default {
  components: {
    ChatsChart,
    TrendsChart,
  },
  computed: {
    ...mapGetters({
      mediflowSettings: 'mediflowSettings/getMediaflowSettings',
      uiMediaflowFlags: 'mediflowSettings/getUIFlags',
      chatsReport: 'nucleus/chatsReport/getReport',
      reportIsFetching: 'nucleus/chatsReport/getUIFlags',
    }),
    chartChatsSlaData() {
      const slaReport = this.chatsReport.sla_report;
      return {
        labels: ['SLA', 'SLA+TRA', 'Exceeded SLA+TRA'],
        datasets: [
          {
            backgroundColor: ['#66e08f', '#fbc862', '#fa6f6f'],
            data: [
              slaReport.within_sla || undefined,
              slaReport.within_tra || undefined,
              slaReport.over_sla || undefined,
            ],
          },
        ],
      };
    },
    chartChatsData() {
      const summary = this.chatsReport.summary_report;
      return {
        labels: ['Agents', 'Abandoned'],
        datasets: [
          {
            backgroundColor: ['#4a72ff', '#46ccf6', '#cd75ed'],
            data: [
              summary.transferred_to_agent || undefined,
              summary.abandoned || undefined,
            ],
          },
        ],
      };
    },
    chartTrendsData() {
      const trendReport = this.chatsReport.trend_report;
      if (trendReport) {
        return {
          labels: Object.keys(trendReport.transferred_to_agent),
          datasets: [
            {
              data: Object.values(trendReport.transferred_to_agent),
              label: 'Agent',
              borderColor: '#8e5ea2',
              pointBackgroundColor: '#8e5ea2',
              lineTension: 0,
              fill: false,
            },
            {
              data: Object.values(trendReport.transferred_to_reserved_agent),
              label: 'Reserved Agent',
              borderColor: '#fa91c9',
              pointBackgroundColor: '#fa91c9',
              lineTension: 0,
              fill: false,
            },
            {
              data: Object.values(trendReport.abandoned),
              label: 'Abandoned',
              borderColor: '#cd75ed',
              pointBackgroundColor: '#cd75ed',
              lineTension: 0,
              fill: false,
            },
          ],
        };
      }
      return false;
    },
  },
  mounted() {
    this.setDefaultRange();
    this.$store.dispatch('mediflowSettings/get').then(() => {
      if (this.mediflowSettings.length) {
        this.mediaflowSettingId = this.mediflowSettings[0].id;
        this.fetchReport();
      }
    });
  },
  data() {
    return {
      dateRange: [],
      mediaflowSettingId: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    fetchReport() {
      const [start_date, end_date] = this.dateRange;
      this.$store.dispatch('nucleus/chatsReport/fetchReport', {
        mediaflow_setting_id: this.mediaflowSettingId,
        start_date,
        end_date,
      });
    },
    setDefaultRange() {
      const end = new Date();
      const start = new Date();
      // 1 week
      start.setDate(start.getDate() - 7);
      this.dateRange = [start, end];
    },
    changeDate() {
      if (this.mediaflowSettingId) this.fetchReport();
    },
    changeMediaflow() {
      this.fetchReport();
    },
  },
};
</script>

<style lang="scss">
.chats-report {
  height: 100%;

  .column-date-picker {
    text-align: end;
  }

  .pie-chart {
    height: 150px;
  }

  .line-chart {
    height: 350px;
  }

  .statistics {
    display: flex;
    flex-direction: column;
    text-align: center;

    .title {
      color: #606266;
      font-size: 13px;
    }

    .value {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .el-col {
    padding-top: 20px;
  }

  .main-header {
    .el-col {
      padding-top: 0;
    }
  }

  .el-form-item {
    margin: 0;
  }

  width: 100%;

  .header {
    color: #606266;
    font-size: 13px;
    text-align: center;
    padding-bottom: 5px;
  }

  .el-card {
    background-color: #f6f9fa;
  }

  .el-form .el-form-item {
    width: 350px;
    display: flex;

    .el-form-item__content {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }
}
</style>
