import { frontendURL } from '../../../helper/URLHelper';

import intent from './intent/Index';
import BotIntents from '././intent/BotIntents';
import agentAdmin from './agentAdmin/Index';
import agentAdminShow from './agentAdmin/Show';
import tenantAdmin from './tenantAdmin/Index';
import customerProfile from './customerProfile/Index';
import response from './response/Index';
import store from '../../../store';
import chatsReport from './chatsReport/Index';
import agentsReport from './agentsReport/Index';
import botSetup from './botSetup/Index';
import index from './Index';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/sentio-manager'),
      name: 'sentio_manager_index',
      roles: ['administrator', 'agent'],
      component: index,
      redirect: () => {
        if (store.getters.getCurrentRole === 'administrator') {
          return { name: 'tenant_admin_index' };
        }
        return { name: 'agent_admin_index' };
      },
      children: [
        {
          path: frontendURL('accounts/:accountId/sentio-manager/intent'),
          name: 'intent_index',
          roles: ['administrator', 'agent'],
          component: intent,
          children: [
            {
              path: frontendURL(
                'accounts/:accountId/sentio-manager/intent/:botId'
              ),
              name: 'bot_intent_index',
              roles: ['administrator'],
              component: BotIntents,
            },
          ],
        },
        {
          path: frontendURL(
            'accounts/:accountId/sentio-manager/customer-profile'
          ),
          name: 'customer_profile_index',
          roles: ['administrator'],
          component: customerProfile,
        },
        {
          path: frontendURL(
            'accounts/:accountId/sentio-manager/agent-admin/:agentId'
          ),
          name: 'agent_admin_show',
          component: agentAdminShow,
          roles: ['administrator', 'agent'],
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/agent-admin'),
          name: 'agent_admin_index',
          roles: ['administrator', 'agent'],
          component: agentAdmin,
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/tenant-admin'),
          name: 'tenant_admin_index',
          roles: ['administrator'],
          component: tenantAdmin,
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/response'),
          name: 'response_index',
          roles: ['administrator'],
          component: response,
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/chats-report'),
          name: 'chats_report_index',
          roles: ['administrator', 'agent'],
          component: chatsReport,
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/agents-report'),
          name: 'agents_report_index',
          roles: ['administrator'],
          component: agentsReport,
        },
        {
          path: frontendURL('accounts/:accountId/sentio-manager/bot-setup'),
          name: 'bot_setup_index',
          roles: ['administrator'],
          component: botSetup,
        },
      ],
    },
  ],
};
