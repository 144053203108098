/* eslint no-console: 0 */

import ApiClient from '../ApiClient';

class SentioAdminBotVendorConfigurations extends ApiClient {
  constructor() {
    super('sentio_manager/bot_vendor_configurations', { accountScoped: true });
  }
}

export default new SentioAdminBotVendorConfigurations();
