import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AgentSkillApi from '../../api/agentSkill';
import Vue from "vue";

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getAgentSkills($state) {
    return $state.records;
  },
  getVerifiedAgentSkills($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, params) => {
    commit(types.default.SET_AGENT_SKILL_FETCHING_STATUS, true);
    try {
      const response = await AgentSkillApi.get(params);
      commit(types.default.SET_AGENT_SKILL_FETCHING_STATUS, false);
      commit(types.default.SET_AGENT_SKILLS, response.data);
    } catch (error) {
      commit(types.default.SET_AGENT_SKILL_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_AGENT_SKILL_CREATING_STATUS, true);
    try {
      const response = await AgentSkillApi.create(agentInfo);
      agentInfo.id = response.data.id;
      commit(types.default.SET_AGENT_SKILL_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_SKILL_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_AGENT_SKILL_UPDATING_STATUS, true);
    try {
      const response = await AgentSkillApi.update(id, agentParams);
      commit(types.default.EDIT_AGENT_SKILL, response.data);
      commit(types.default.SET_AGENT_SKILL_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_SKILL_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },

  updatePresence: async ({ commit }, data) => {
    commit(types.default.SET_AGENT_SKILL_UPDATING_STATUS, true);
    commit(types.default.UPDATE_AGENT_SKILLS_PRESENCE, data);
    commit(types.default.SET_AGENT_SKILL_UPDATING_STATUS, false);
  },

  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_AGENT_SKILL_DELETING_STATUS, true);
    try {
      await AgentSkillApi.delete(agentId);
      commit(types.default.DELETE_AGENT_SKILL, agentId);
      commit(types.default.SET_AGENT_SKILL_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_SKILL_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_AGENT_SKILL_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_AGENT_SKILL_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_AGENT_SKILL_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_AGENT_SKILL_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_AGENT_SKILLS]: MutationHelpers.set,
  [types.default.ADD_AGENT_SKILL]: MutationHelpers.create,
  [types.default.EDIT_AGENT_SKILL]: MutationHelpers.update,
  [types.default.DELETE_AGENT_SKILL]: MutationHelpers.destroy,
  [types.default.UPDATE_AGENT_SKILLS_PRESENCE]: MutationHelpers.updatePresence,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
