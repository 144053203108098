<template>
  <div class="content-box">
    <h6>Skills</h6>
    <el-dialog
      v-loading="isSaving"
      width="30%"
      :title="skillItem.id ? 'Edit Skill' : 'Add Skill'"
      :visible.sync="skillModalVisible"
    >
      <el-form
        ref="skillForm"
        label-position="left"
        :rules="skillRules"
        :model="skillItem"
      >
        <el-form-item label="Name" label-width="150px" prop="name">
          <el-input v-model="skillItem.name" />
        </el-form-item>

        <el-form-item
          label="Routing weight"
          prop="routing_weight"
          label-width="150px"
        >
          <el-input-number
            v-model="skillItem.routing_weight"
            controls-position="right"
            :min="0"
            :max="5"
          />
        </el-form-item>
        <el-form-item label="Self-administered" label-width="150px">
          <el-checkbox v-model="skillItem.self_administered" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModals">Cancel</el-button>
        <el-button
          type="primary"
          @click="createOrUpdateModel(skillItem, 'skillForm', 'skillSettings')"
        >
          {{ skillItem.id ? 'Save Changes' : 'Confirm' }}
        </el-button>
      </span>
    </el-dialog>

    <el-table
      v-loading="uiSkillFlags.isFetching"
      size="mini"
      :data="skillSettingsList"
      empty-text="No Data"
      @cell-mouse-enter="cellEnter"
      @cell-mouse-leave="cellLeave"
    >
      <el-table-column label="ID" prop="id" width="100" />
      <el-table-column label="Skills" prop="name" />
      <el-table-column align="center" prop="routing_weight">
        <template slot="header">
          Routing weight
          <el-tooltip
            placement="top"
            content="Importance level of skill in the routing process"
            effect="light"
          >
            <i class="el-icon-info" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label-class-name="text-center"
        align="center"
        label="Self-administered"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.self_administered" disabled />
        </template>
      </el-table-column>
      <el-table-column>
        <div v-show="scope.row.details" slot-scope="scope">
          <el-button
            v-if="scope.row.id"
            v-loading="scope.row.loading"
            type="text"
            icon="el-icon-edit"
            @click="showSkillModal(scope.row)"
          />
          <el-popconfirm
            :title="
              'Are you sure to delete this skill &quot;' +
                scope.row.name +
                '&quot;?'
            "
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="deleteSkill(scope.row)"
          >
            <el-button
              v-if="scope.row.id"
              slot="reference"
              v-loading="scope.row.loading"
              type="text"
              icon="el-icon-close"
            />
          </el-popconfirm>
        </div>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      size="small"
      plain
      @click="showSkillModal({ routing_weight: 0 })"
    >
      Add Skill
    </el-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  data() {
    return {
      skillItem: {},
      skillModalVisible: false,
      skillRules: {
        name: [
          { required: true, message: 'Please input name', trigger: 'change' },
        ],
        thresholds: [
          {
            required: true,
            message: 'Please select Thresholds',
            trigger: 'change',
          },
        ],
        routing_weight: [
          {
            required: true,
            message: 'Please select Routing Weight',
            trigger: 'change',
          },
        ],
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      skillSettingsList: 'skillSettings/getSkillSettings',
      uiSkillFlags: 'skillSettings/getUIFlags',
    }),
  },
  mounted() {
    this.$store.dispatch('skillSettings/get');
  },
  methods: {
    showSkillModal(item) {
      this.skillItem = { ...item };
      this.skillModalVisible = true;
      Vue.nextTick(() => {
        this.$refs.skillForm.clearValidate();
      });
    },
    cellEnter(row) {
      Vue.set(row, 'details', true);
    },
    cellLeave(row) {
      Vue.set(row, 'details', false);
    },
    closeModals() {
      this.skillModalVisible = false;
    },
    createOrUpdateModel() {
      const item = this.skillItem;
      // eslint-disable-next-line consistent-return
      this.$refs.skillForm.validate(async valid => {
        if (valid) {
          this.isSaving = true;
          try {
            if (item.id) {
              await this.$store.dispatch(`skillSettings/update`, item);
              this.showAlert('Updated');
            } else {
              await this.$store.dispatch(`skillSettings/create`, item);
              this.showAlert('Created');
            }
            this.closeModals();
            this.isSaving = false;
            // eslint-disable-next-line no-empty
          } catch (error) {}
        } else {
          return false;
        }
      });
    },
    async deleteSkill(item) {
      Vue.set(item, 'loading', true);
      await this.$store.dispatch('skillSettings/delete', item.id);
      this.showAlert('Deleted');
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  width: 1200px;
}
</style>
