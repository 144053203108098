/* eslint no-console: 0 */

import ApiClient from '../ApiClient';

class SentioAdminExternalBotsAPI extends ApiClient {
  constructor() {
    super('bots', { accountScoped: true });
    this.botVendorConfigurationId = null;
  }

  get url() {
    return `${this.baseUrl()}/sentio_manager/bot_vendor_configurations/${
      this.botVendorConfigurationId
    }/bots?registered=false`;
  }

  get(botVendorConfigurationId) {
    this.botVendorConfigurationId = botVendorConfigurationId;
    return super.get();
  }
}

export default new SentioAdminExternalBotsAPI();
