import Vue from 'vue';
import Vuex from 'vuex';

import accounts from './modules/accounts';
import agents from './modules/agents';
import attributes from './modules/attributes';
import auth from './modules/auth';
import automations from './modules/automations';
import bulkActions from './modules/bulkActions';
import campaigns from './modules/campaigns';
import cannedResponse from './modules/cannedResponse';
import contactConversations from './modules/contactConversations';
import contactLabels from './modules/contactLabels';
import contactNotes from './modules/contactNotes';
import contacts from './modules/contacts';
import conversationLabels from './modules/conversationLabels';
import conversationMetadata from './modules/conversationMetadata';
import conversationPage from './modules/conversationPage';
import conversations from './modules/conversations';
import conversationSearch from './modules/conversationSearch';
import conversationStats from './modules/conversationStats';
import conversationTypingStatus from './modules/conversationTypingStatus';
import csat from './modules/csat';
import customViews from './modules/customViews';
import dashboardApps from './modules/dashboardApps';
import globalConfig from 'shared/store/globalConfig';
import inboxAssignableAgents from './modules/inboxAssignableAgents';
import inboxes from './modules/inboxes';
import inboxMembers from './modules/inboxMembers';
import integrations from './modules/integrations';
import labels from './modules/labels';
import notifications from './modules/notifications';
import reports from './modules/reports';
import teamMembers from './modules/teamMembers';
import teams from './modules/teams';
import userNotificationSettings from './modules/userNotificationSettings';
import webhooks from './modules/webhooks';
import skillSettings from './modules/skillSettings';
import agentSkills from './modules/agentSkills';
import mediflowSettings from './modules/mediflowSettings';
import agentMediaflows from './modules/agentMediaflows';
import intent from './modules/intent';
import chatsReport from './modules/nucleus/chatsReport';
import bots from './modules/sentioAdmin/bots';
import externalBots from './modules/sentioAdmin/externalBots';
import botVendorConfigurations from './modules/sentioAdmin/botVendorConfigurations';
import articles from './modules/helpCenterArticles';
import portals from './modules/helpCenterPortals';
import categories from './modules/helpCenterCategories';
import hooks from './modules/hooks';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
  modules: {
    accounts,
    hooks,
    intent,
    mediflowSettings,
    agentMediaflows,
    agents,
    attributes,
    auth,
    automations,
    bulkActions,
    campaigns,
    cannedResponse,
    contactConversations,
    contactLabels,
    contactNotes,
    contacts,
    conversationLabels,
    conversationMetadata,
    conversationPage,
    conversations,
    conversationSearch,
    conversationStats,
    conversationTypingStatus,
    csat,
    customViews,
    dashboardApps,
    globalConfig,
    inboxAssignableAgents,
    skillSettings,
    agentSkills,
    inboxes,
    inboxMembers,
    integrations,
    labels,
    notifications,
    reports,
    teamMembers,
    teams,
    userNotificationSettings,
    webhooks,
    articles,
    portals,
    categories,
    sentioAdmin: {
      namespaced: true,
      modules: {
        externalBots,
        bots,
        botVendorConfigurations,
      },
    },
    nucleus: {
      namespaced: true,
      modules: {
        chatsReport,
      },
    },
  },
});
