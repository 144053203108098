<template>
  <div v-if="agent" class="agent-admin-show column content-box">
    <back-button button-label="Back" :back-url="backUrl" />

    <h4>Agent Profile</h4>

    <div class="content-box">
      <h5>{{ agent.name }}</h5>

      <el-form ref="form" label-position="left" :model="agent">
        <el-form-item label="ID" label-width="210px">
          <span>{{ agent.id }}</span>
        </el-form-item>
        <el-form-item label="Email" label-width="210px">
          <span>{{ agent.email || '-' }}</span>
        </el-form-item>
        <el-form-item label="Phone" label-width="210px">
            <el-input
                    v-model="agent.phone_number"
                    :disabled="!isAdmin"
                    controls-position="right"
                    :min="1"
            />
        </el-form-item>
        <el-form-item label="Reserve" label-width="210px">
          <el-checkbox v-model="agent.reserved" :disabled="!isAdmin" />
        </el-form-item>

        <el-form-item label-width="210px">
          <span slot="label">
            Conversations
            <el-tooltip
              effect="light"
              content="The maximum number of conversations that can be assigned to an agent."
              placement="top-start"
            >
              <i class="el-icon-info" />
            </el-tooltip>
          </span>
          <el-input-number
            v-model="agent.max_conversations"
            :disabled="!isAdmin"
            controls-position="right"
            :min="1"
          />
        </el-form-item>
      </el-form>

      <h6>Skills</h6>
      <el-table
        size="mini"
        empty-text="No Data"
        :data="agentSkillList"
        style="width: 500px"
      >
        <el-table-column label="Skills">
          <template slot-scope="scope">
            <b v-if="scope.row.level">
              {{ scope.row.skill_setting.name }}
            </b>
            <span v-else class="disabled">
              {{ scope.row.skill_setting.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Proficiency level">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.level"
              :disabled="
                isAdmin ? false : !scope.row.skill_setting.self_administered
              "
              controls-position="right"
              :min="0"
              :max="5"
              @change="editRow(scope.row, true)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content-box">
      <h6>Mediaflows</h6>

      <el-table
        size="mini"
        empty-text="No Data"
        :data="agentMeadiflowList"
        style="width: 500px"
      >
        <el-table-column>
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.enabled"
              @change="editRow(scope.row, true)"
            />
            {{ scope.row.mediaflow_setting.name }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content-box">
      <el-button type="primary" @click="saveChanges">
        Save Changes
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../../components/widgets/BackButton';
import Vue from 'vue';

export default {
  components: {
    BackButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      agentSkillList: 'agentSkills/getAgentSkills',
      agentMeadiflowList: 'agentMediaflows/getAgentMediaflows',
      uiFlags: 'agents/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    agent() {
      return this.agentList.find(it => it.id == this.$route.params.agentId);
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/sentio-manager/agent-admin`;
    },
    isAdmin() {
      return this.currentUser.role === 'administrator';
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.$store.dispatch('agentSkills/get', this.$route.params.agentId);
    this.$store.dispatch('agentMediaflows/get', this.$route.params.agentId);
  },
  methods: {
    async saveChanges() {
      if (this.isAdmin) {
        await this.$store.dispatch('agents/update', this.agent);
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line no-restricted-syntax
        for await (const agentMeadiflow of this.agentMeadiflowList) {
          if (agentMeadiflow.edit) {
            await this.updateMediaflow(agentMeadiflow);
          }
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const agentSkill of this.agentSkillList) {
        if (agentSkill.edit) {
          await this.saveAgentSkill(agentSkill);
        }
      }
      this.showAlert('Saved');
      this.$router.push({ name: 'agent_admin_index' });
    },
    editRow(row, state) {
      Vue.set(row, 'edit', state);
    },
    async saveAgentSkill(agentSkill) {
      if (agentSkill.id) {
        await this.$store.dispatch('agentSkills/update', agentSkill);
      } else {
        await this.$store.dispatch('agentSkills/create', agentSkill);
      }
      this.editRow(agentSkill, false);
    },
    async updateMediaflow(mediaflow) {
      if (mediaflow.id) {
        await this.$store.dispatch('agentMediaflows/update', mediaflow);
      } else {
        await this.$store.dispatch('agentMediaflows/create', mediaflow);
      }
      this.editRow(mediaflow, false);
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss">
.agent-admin-show {
  .el-checkbox {
    padding-right: 10px;
  }

  .back-button {
    padding-bottom: 10px;
  }

  h4 {
    //text-align: center;
  }

  h6 {
    padding-bottom: 10px;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .el-select {
    .el-input {
      margin-bottom: 0;
    }

    input {
      margin: 0 !important;
    }
  }

  .search-intent {
    &.el-input {
      margin-bottom: 20px;
      width: 300px !important;

      input {
        margin: 0 !important;
      }
    }
  }

  .el-input {
    input {
      margin: 0 !important;
    }

    width: 100% !important;
    margin: 0 !important;
  }

  .el-input-number {
    .el-input {
      width: 100% !important;
      margin: 0 !important;
    }

    input {
      margin: 0 !important;
    }
  }

  .el-form {
    .el-form {
      width: 100% !important;
    }
  }

  .el-button.add-intent {
    margin-top: 20px;
  }

  table thead th {
    font-weight: 600;
    color: #383636;
    text-transform: none;
  }

  table tr,
  table td {
    border: none !important;
  }

  .el-input-number {
    width: 140px;
  }

  .el-table::before {
    display: none;
  }

  .el-table {
    .disabled {
      color: var(--b-400);
    }
  }

  .el-select {
    width: 100%;
  }

  input[readonly] {
    background-color: white !important;
  }
}
</style>
