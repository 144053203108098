<template>
  <div class="content-box intent-classifier">
    <h5>Intent Classifier</h5>
    <el-menu
      v-if="botsList.length"
      v-loading="uiFlags.isFetching"
      class="el-menu-demo"
      :default-active="$route.path"
      mode="horizontal"
      router
    >
      <el-menu-item
        v-for="bot in botsList"
        :key="bot.id"
        :index="intentBotUrl(bot.id)"
      >
        {{ bot.name }}
        <el-tag size="small" type="info">
          {{ bot.bot_vendor_configuration.vendor }}
        </el-tag>
      </el-menu-item>
    </el-menu>
    <div v-else>
      <el-link icon="el-icon-plus" @click="redirectToBotSetup">
        Add Bot
      </el-link>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  mixins: [globalConfigMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      botsList: 'sentioAdmin/bots/getBots',
      accountId: 'getCurrentAccountId',
      uiFlags: 'sentioAdmin/bots/getUIFlags',
    }),
  },
  watch: {
    botsList: function(botsList) {
      if (botsList && botsList.length && this.$route.name === 'intent_index') {
        this.$router.push(this.intentBotUrl(botsList[0].id));
      }
    },
  },
  mounted() {
    // this.$store.dispatch('agents/get');
    this.$store.dispatch('skillSettings/get');
    this.$store.dispatch('mediflowSettings/get');
    this.$store.dispatch('sentioAdmin/bots/get');
  },
  methods: {
    intentBotUrl(id) {
      return frontendURL(
        `accounts/${this.accountId}/sentio-manager/intent/${id}`
      );
    },
    redirectToBotSetup() {
      this.$router.push(
        frontendURL(`accounts/${this.accountId}/sentio-manager/bot-setup`)
      );
    },
  },
};
</script>

<style lang="scss"></style>
