<template>
  <div class="content-box">
    <h6>Mediaflows</h6>

    <el-dialog
      v-loading="isSaving"
      width="500px"
      title="Configure Mediaflow"
      :visible.sync="mediaflowModalVisible"
    >
      <el-form
        ref="mediaflowForm"
        label-position="left"
        :rules="mediaflowRules"
        :model="mediaflowItem"
      >
        <el-form-item label="Name" label-width="150px" prop="name">
          <el-input v-model="mediaflowItem.name" />
        </el-form-item>

        <el-form-item label="SLA, sec" prop="sla" label-width="150px">
          <el-input-number
            v-model="mediaflowItem.sla"
            controls-position="right"
            :min="0"
          />
        </el-form-item>
        <el-form-item label="TRA, sec" prop="tra" label-width="150px">
          <el-input-number
            v-model="mediaflowItem.tra"
            controls-position="right"
            :min="1"
          />
        </el-form-item>
        <el-form-item label="IASD, sec" prop="tra" label-width="150px">
          <el-input-number
            v-model="mediaflowItem.iasd"
            controls-position="right"
            :min="0"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModals">Cancel</el-button>
        <el-button type="primary" @click="updateModel">
          Save Changes
        </el-button>
      </span>
    </el-dialog>

    <el-table
      v-loading="uiMediaflowFlags.isFetching"
      size="mini"
      :select-on-indeterminate="false"
      :data="mediflowSettings"
      empty-text="No Data"
      @cell-mouse-enter="cellEnter"
      @cell-mouse-leave="cellLeave"
    >
      <el-table-column label="Mediaflows" prop="name">
        <div slot-scope="scope" class="flex-container align-middle">
          {{ scope.row.name }}
        </div>
      </el-table-column>
      <el-table-column width="200" align="center" prop="sla">
        <template slot="header">
          SLA, sec
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              Service Level on Response Time
              <br />
              Range: 0 - 120 seconds
            </div>
            <i class="el-icon-info" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="200" align="center" prop="tra">
        <template slot="header">
          TRA, sec
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              Threshold of Reserve Agents
              <br />
              Range: 5 - 120 seconds
            </div>
            <i class="el-icon-info" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="200" align="center" prop="iasd">
        <template slot="header">
          IASD, sec
          <el-tooltip
            placement="top"
            content="Initial Average Service Duration"
            effect="light"
          >
            <i class="el-icon-info" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column>
        <div v-show="scope.row.details" slot-scope="scope">
          <el-button
            v-if="scope.row.id"
            v-loading="scope.row.loading"
            type="text"
            icon="el-icon-edit"
            @click="showMediaflowModal(scope.row)"
          />
          <el-popconfirm
            :title="
              'Are you sure to delete this Mediaflow &quot;' +
                scope.row.name +
                '&quot;?'
            "
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="deleteMediaflow(scope.row)"
          >
            <el-button
              v-if="scope.row.id"
              slot="reference"
              v-loading="scope.row.loading"
              type="text"
              icon="el-icon-close"
            />
          </el-popconfirm>
        </div>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      size="small"
      plain
      @click="showMediaflowModal({})"
    >
      Add Mediaflow
    </el-button>
  </div>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ViewMediaflow',
  computed: {
    ...mapGetters({
      mediflowSettings: 'mediflowSettings/getMediaflowSettings',
      uiSkillFlags: 'skillSettings/getUIFlags',
      uiMediaflowFlags: 'mediflowSettings/getUIFlags',
    }),
  },
  data() {
    return {
      mediaflowItem: {},
      mediaflowModalVisible: false,
      mediaflowRules: {
        name: [
          { required: true, message: 'Please input name', trigger: 'change' },
        ],
        tra: [
          {
            required: true,
            message: 'Please select TRA',
            trigger: 'change',
          },
        ],
        sla: [
          {
            required: true,
            message: 'Please select SLA',
            trigger: 'change',
          },
        ],
        iasd: [
          {
            required: true,
            message: 'Please select SLA',
            trigger: 'change',
          },
        ],
      },
      isSaving: false,
    };
  },
  mounted() {
    this.$store.dispatch('mediflowSettings/get');
  },
  methods: {
    showMediaflowModal(item) {
      this.mediaflowItem = { ...item };
      this.mediaflowModalVisible = true;
      Vue.nextTick(() => {
        this.$refs.mediaflowForm.clearValidate();
      });
    },
    computedInboxClass(type) {
      return getInboxClassByType(type);
    },
    closeModals() {
      this.mediaflowModalVisible = false;
    },
    cellEnter(row) {
      Vue.set(row, 'details', true);
    },
    cellLeave(row) {
      Vue.set(row, 'details', false);
    },
    async deleteMediaflow(item) {
      Vue.set(item, 'loading', true);
      await this.$store.dispatch('mediflowSettings/delete', item.id);
      this.showAlert('Deleted');
    },
    updateModel() {
      // eslint-disable-next-line consistent-return
      this.$refs.mediaflowForm.validate(async valid => {
        if (valid) {
          this.isSaving = true;
          try {
            if (this.mediaflowItem.id) {
              await this.$store.dispatch(
                'mediflowSettings/update',
                this.mediaflowItem
              );
              this.showAlert('Updated');
            } else {
              await this.$store.dispatch(
                'mediflowSettings/create',
                this.mediaflowItem
              );
              this.showAlert('Created');
            }
            this.closeModals();
            this.isSaving = false;
            // eslint-disable-next-line no-empty
          } catch (error) {}
        } else {
          return false;
        }
      });
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  width: 900px;
}
</style>
