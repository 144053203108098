<template>
  <div class="column content-box">
    <h5>Agents Report</h5>
    SOON
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss"></style>
