<template>
  <div class="tenant-admin column content-box">
    <h5>Tenant Admin</h5>
    <ViewSkills />
    <el-divider />
    <ViewMediaflow />
  </div>
</template>
<script>
import ViewMediaflow from './ViewMediaflow';
import ViewSkills from './ViewSkills';

export default {
  components: { ViewMediaflow, ViewSkills },
};
</script>

<style lang="scss">
.tenant-admin {
  .el-table {
    .el-button {
      padding: 0 4px !important;
    }
  }

  h4 {
    //text-align: center;
  }

  h6 {
    padding-bottom: 10px;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .el-select {
    .el-input {
      margin-bottom: 0;
    }

    input {
      margin: 0 !important;
    }
  }

  .search-intent {
    &.el-input {
      margin-bottom: 20px;
      width: 300px !important;

      input {
        margin: 0 !important;
      }
    }
  }

  .el-input {
    input {
      margin: 0 !important;
    }

    width: 100% !important;
    margin: 0 !important;
  }

  .el-input-number {
    .el-input {
      width: 100% !important;
      margin: 0 !important;
    }

    input {
      margin: 0 !important;
    }
  }

  .el-form {
    .el-form {
      width: 100% !important;
    }
  }

  .el-button.add-intent {
    margin-top: 20px;
  }

  table thead th {
    font-weight: 600;
    color: #383636;
    text-transform: none;
  }

  table tr,
  table td {
    border: none !important;
  }

  .el-input-number {
    width: 140px;
  }

  .el-table::before {
    display: none;
  }

  .el-select {
    width: 100%;
  }

  input[readonly] {
    background-color: white !important;
  }
}
</style>
