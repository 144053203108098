<template>
  <div class="agent-admin column content-box">
    <h5>Agent Admin</h5>
    <div class="content-box">
      <div class="columns with-right-space ">
        <el-table
          size="mini"
          empty-text="No Data"
          :data="availableAgentList"
          style="width: 100%"
        >
          <el-table-column prop="id" label="ID" width="50" />
          <el-table-column prop="name" label="Full Name">
            <div slot-scope="scope" :title="scope.row.name">
              {{ scope.row.name }}
            </div>
          </el-table-column>
          <el-table-column prop="email" label="Skills">
            <div
              slot-scope="scope"
              :title="
                scope.row.agent_skills
                  .filter(it => it.level)
                  .map(it => `${it.name}(${it.level})`)
              "
            >
              <el-tag
                v-for="skill in scope.row.agent_skills.filter(it => it.level)"
                :key="skill.name"
              >
                {{ skill.name }}(<b>{{ skill.level }}</b
                >)
              </el-tag>
            </div>
          </el-table-column>
          <el-table-column
            width="120"
            align="center"
            prop="reserved_status"
            label="Reserve Agent"
          />
          <el-table-column prop="status" label="Status" width="120" />
          <el-table-column width="80">
            <template slot-scope="scope">
              <router-link
                v-slot="{ navigate }"
                :to="
                  addAccountScoping(
                    `sentio-manager/agent-admin/${scope.row.id}`
                  )
                "
                custom
              >
                <el-button type="text" size="small" @click="navigate">
                  Details
                </el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import accountMixin from '../../../../mixins/account';

export default {
  components: {},
  mixins: [accountMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
    }),
    availableAgentList() {
      if (this.currentUser.role !== 'administrator') {
        return this.agentList.filter(it => it.id === this.currentUser.id);
      }
      return this.agentList;
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {},
};
</script>

<style lang="scss">
.agent-admin {
  .el-table {
    tr td .cell {
      & > div {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .el-button {
      padding: 0 4px !important;
    }
  }

  table tr,
  table td {
    border: none !important;
  }

  .el-table::before {
    display: none;
  }
}

table thead th {
  text-transform: none;
}

.el-table__header-wrapper {
  table {
    margin: 0 !important;
  }
}
</style>
