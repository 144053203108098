// eslint-disable-next-line no-unused-vars
/* global axios */
import ApiClient from './ApiClient';

class IntentAPI extends ApiClient {
  constructor() {
    super('mediaflow_settings', { accountScoped: true });
  }
}

export default new IntentAPI();
