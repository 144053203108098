/* eslint no-console: 0 */

import ApiClient from '../ApiClient';

class SentioAdminBotsAPI extends ApiClient {
  constructor() {
    super('sentio_manager/bots', { accountScoped: true });
  }
}

export default new SentioAdminBotsAPI();
