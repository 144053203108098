import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../../mutation-types';
import ExternalBotsAPI from '../../../api/sentioAdmin/externalBots';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getExternalBots($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, botVendorConfigurationId) => {
    commit(types.default.SET_NUCLEUS_EXTERNAL_BOT_FETCHING_STATUS, true);
    try {
      const response = await ExternalBotsAPI.get(botVendorConfigurationId);
      commit(types.default.SET_NUCLEUS_EXTERNAL_BOT_FETCHING_STATUS, false);
      commit(types.default.SET_NUCLEUS_EXTERNAL_BOTS, response.data);
    } catch (error) {
      commit(types.default.SET_NUCLEUS_EXTERNAL_BOT_FETCHING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.default.SET_NUCLEUS_EXTERNAL_BOT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },

  [types.default.SET_NUCLEUS_EXTERNAL_BOTS]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
